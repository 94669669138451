<template>
    <section>
        <p style="margin-left:35%;margin-top:15%">  This one is in to do list will have to start soon</p>
    </section>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name:"templates-PreviewTemplate",
    data() {
         return {
            entityDocumentTemplates: []
        };
    },
    mounted() {

    },
    computed: 
    {
        ...mapGetters("entities", ["getEntityDocumentsDataStatus","getEntityDocumentsData"]),
    },
    methods:{
       
    }
}
</script>
<style scoped>

</style>